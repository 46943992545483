/*
    Semantic UI Overrides / Fixes / Additions
*/
.ui.ordered.steps .step.completed:before,
.ui.steps .step.completed>.icon:before {
/*    content: '\2713';*/
    content: '\2714';
}

.ui.pagination {
    &.menu {
        button.item {
            border: none;

            &:active,
            &:focus {
                outline: none;
            }
        }
    }
}

.ui, .ui * {
    box-sizing: border-box;
}



.ui.table.paneled {
    border-width: 1px 0;
    border-radius: 0;
}




.ui.pointing.above.west.label:before {
    left: 10%;
}


.ui.menu .item.disabled md-icon,
.ui.menu .item.disabled:hover md-icon {
    fill: #ccc;
}


.ui.top.attached.header {

    // Inside of a dialog
    md-dialog & {
        margin: 0;
    }

}




.ui.message.icon .icon md-icon {
    width: 36px;
    height: 36px;
}

.ui.message.thin {
    padding: 8px !important;
}



/*
    Loading Panel
*/
.ui.loading.form:before {
    border-radius: 4px;
}
.ui.loading.form:after {

    border-width: 0.4em;
    border-color: #ffeb3b rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
}


//.ui.table {
//
//     th[colspan] {
//         text-align: center;
//     }
//
//}